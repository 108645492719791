<div>
  <app-page-title
    class="d-none d-md-block"
    [title]="'Servo Rechner'"
    [subTitle]="'für Vermittler der Servo GmbH.'"></app-page-title>

  <div class="container">
    <div class="row">
      <app-calculator-list></app-calculator-list>
    </div>
  </div>
</div>
