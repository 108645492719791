<div class="servo-card">
  <div class="card-header align-items-center">
    <div class="title">
      <h5 class="mb-0 d-flex">Aktive {{ getPluralUsageName() }}</h5>
    </div>

    <div class="icons">
      <div>
        <button
          (click)="addUser.emit()"
          class="btn btn-sm btn-primary"
          type="button">

          {{ getSingleUsageName() }} hinzufügen
        </button>
      </div>
    </div>
  </div>

  @if (!!_users) {
    <p-table
      [value]="_users">
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th>HelveticWarranty Id</th>
          <th>&nbsp;</th>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-user>
        <tr
          class="user-row"
          (click)="editUser.emit(user)">

          <td>
            @if (user.isPerson) {
              {{ user.firstname }} {{ user.lastname }}
            } @else {
              {{ user.companyName }}
            }
          </td>

          <td>{{ user.hwAdvertiserId }}</td>

          <td class="text-end">
            <i class="bi bi-chevron-right fw-bold"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  }
</div>