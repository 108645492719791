<div class="password-box">
  <form
    [formGroup]="form"
    (ngSubmit)="submit()">

    <img
      src="https://myservo.at/wp-content/uploads/2021/05/servo-logo-400.png"
      class="mb-4"
      alt="servo-logo"
      height="57">

    <div class="mb-3 text-center">
      <span class="fw-medium">
        Sie haben Ihr initales Einmal-Passwort verwendet. Bitte setzen Sie ein neues.
      </span>
    </div>

    <div class="form-floating">
      <input
        [formControl]="form.controls.password"
        type="password"
        class="form-control"
        id="floatingPassword"
        placeholder="Password">
      <label for="floatingPassword">Passwort</label>
    </div>

    <button
      class="btn btn-primary w-100 py-2 mt-3"
      type="submit">
      Neues Passwort setzen
    </button>
  </form>
</div>
