<div>
  <app-page-title
    [title]="'Vermittler'"
    class="d-none d-md-block"></app-page-title>

  <div class="container">
    <div class="row">
      @if (userSaveSuccess) {
        <div class="col-md-8 offset-md-2">
          <div
            class="alert alert-success"
            role="alert">
            Vermittler wurde erfolgreich angelegt!
          </div>
        </div>
      }

      @if (userSaveError) {
        <div class="col-md-8 offset-md-2">
          <div
            class="alert alert-danger"
            role="alert">
            {{ this.userSaveError }}
          </div>
        </div>
      }

      @if (!showAddUser) {
        <div class="col-md-8 offset-md-2">
          <app-user-list
            (addUser)="showAddUser = true"
            (editUser)="onEditUser($event)"
            [isUsedForMakler]="true"
            [users]="users$ | async"></app-user-list>
        </div>
      }

      @if (showAddUser) {
        <div class="col-md-8 offset-md-2">
          <app-user-add
            [isUsedForMakler]="true"
            [user]="userToEdit"
            (submitUser)="submitUser($event)"
            (discardChanges)="discardChanges()"></app-user-add>
        </div>
      }
    </div>
  </div>
</div>
