<div class="premium-calculation">
  <small class="text-muted">
    Handy/Smartphone, Tablet, Notebook, Kamera/Fotoapparat
  </small>

  <form
    (ngSubmit)="submit()"
    [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3 mt-3">
          <label
            class="form-label"
            for="price">Kaufpreis (inkl. Zubehör)</label>

          <input
            [formControl]="form.controls.price"
            class="form-control form-control-sm"
            id="price"
            type="number">
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3 mt-3">
          <label
            class="form-label"
            for="premium">Prämie jährlich</label>

          <input
            [formControl]="form.controls.premium"
            class="form-control form-control-sm"
            id="premium"
            type="number">
        </div>
      </div>
    </div>

    <div>
      @if (showError) {
        <div>
          Der Kaufpreis muss zwischen 0 € und 5.000 € liegen!
        </div>
      }

      <button
        class="btn btn-sm btn-primary ms-auto d-flex"
        type="submit">Antrag erstellen
      </button>
    </div>
  </form>
</div>
