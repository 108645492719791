<div class="servo-card">
  <div class="card-header align-items-center">
    <div class="title">
      <h5 class="mb-0 d-flex">
        @if (user) {
          {{ getSingleUsageName() }} bearbeiten
        } @else {
          {{ getSingleUsageName() }} hinzufügen
        }
      </h5>
    </div>

    <div class="icons">
      <div>
        <button
          (click)="discardChanges.emit()"
          class="btn btn-sm btn-secondary"
          type="button">

          @if (user) {
            Zurück
          } @else {
            Verwerfen
          }
        </button>
      </div>

      <div>
        <button
          (click)="submit()"
          class="btn btn-sm btn-primary"
          type="button">

          {{ getSingleUsageName() }} speichern
        </button>
      </div>
    </div>
  </div>

  <div class="card-body">
    <form [formGroup]="form">
      <div class="row g-3 mb-3">
        <div class="col-sm-4">
          <div class="form-check">
            <input
              [formControl]="form.controls.isPerson"
              class="form-check-input"
              id="flexCheckDefault"
              type="checkbox">

            <label
              class="form-check-label"
              for="flexCheckDefault">
              Privat-Person
            </label>

          </div>
        </div>
      </div>

      <div class="row g-3 mb-3">
        @if (form.controls.isPerson.value === true) {

          <div class="col-sm-4">
            <label
              for="firstname"
              class="form-label">Vorname</label>

            <input
              [formControl]="form.controls.firstname"
              type="text"
              class="form-control form-control-sm"
              id="firstname" />
          </div>

          <div class="col-sm-4">
            <label
              for="lastname"
              class="form-label">Nachname</label>

            <input
              [formControl]="form.controls.lastname"
              type="text"
              class="form-control form-control-sm"
              id="lastname" />
          </div>
        } @else {
          <div class="col-sm-4">
            <label
              for="firstname"
              class="form-label">Firma</label>

            <input
              [formControl]="form.controls.companyName"
              type="text"
              class="form-control form-control-sm"
              id="companyName" />
          </div>
        }

        <div class="col-sm-4">
          <label
            class="form-label"
            for="hwAdvertiserId">Werbernummer HelveticWarranty</label>

          <input
            [formControl]="form.controls.hwAdvertiserId"
            class="form-control form-control-sm"
            id="hwAdvertiserId"
            type="number" />

          @if (form.controls.hwAdvertiserId.hasError('max')) {
            <div class="invalid-feedback">
              Advertiser Id darf maximal 11 Zeichen lang sein!
            </div>
          } @else {
            <small class="text-muted">
              Für Provisionsabrechnung mit Helvetic Warranty
            </small>
          }
        </div>

        <div class="col-sm-4">
          <label
            class="form-label"
            for="servoAdvertiserId">Werbernummer Servo</label>

          <input
            [formControl]="form.controls.servoAdvertiserId"
            class="form-control form-control-sm"
            id="servoAdvertiserId"
            type="text" />

          <small class="text-muted">
            Für QR Code / Erkennung für Servo
          </small>
        </div>


        @if (isUsedForMakler) {
          <div class="col-sm-6">
            <label
              for="email"
              class="form-label">
              E-Mail für Login
            </label>

            <input
              [formControl]="form.controls.email"
              type="text"
              class="form-control form-control-sm"
              id="email" />
          </div>
        }

        <div class="col-sm-6">
          <label
            class="form-label"
            for="mailForCc">E-Mail Kopie an</label>

          <input
            [formControl]="form.controls.additionalMailForCc"
            class="form-control form-control-sm"
            id="mailForCC"
            type="text" />

          <small class="text-muted">
            CC: Kopie des Antrags
          </small>
        </div>

        @if (isUsedForMakler) {
          <div class="col-sm-6">
            <label
              for="logoUrl"
              class="form-label">Logo URL</label>

            <input
              [formControl]="form.controls.logoUrl"
              type="text"
              class="form-control form-control-sm"
              id="logoUrl" />
          </div>
        }
      </div>
    </form>

    @if (!isUsedForMakler) {
      <hr />

      <div class="row g-3 mb-3">
        <div class="col-auto">
          <button
            class="btn btn-sm btn-secondary"
            (click)="createAdvertiserLink()"
            [disabled]="!form.controls.hwAdvertiserId.value">

            QR-Code erstellen
          </button>
        </div>

        @if (advertiserLink) {
          <div
            class="col-12">

            Link für Bike Rechner: <span class="fw-medium">{{ advertiserLink }}</span> <br>

            @if (showCopyMessage) {
              <small
                class="text-muted">
                <i class="bi bi-check-circle"></i> Link in Zwischenablage kopiert!
              </small>
            }
          </div>
        }

        @if (advertiserLink) {
          <div
            class="col-12">
            <div class="qrcodeImage">
              <qrcode
                [qrdata]="advertiserLink"
                [allowEmptyString]="true"
                [alt]="'A custom alt attribute'"
                [ariaLabel]="'QR Code image with the following content...'"
                [cssClass]="'center'"
                [colorDark]="'#000000'"
                [colorLight]="'#ffffffff'"
                [elementType]="'img'"
                [margin]="0"
                [scale]="1"
                [title]="'A custom title attribute'"
                [width]="250"
                (qrCodeURL)="downloadQrCode($event)"
              ></qrcode>
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
