import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { UserModel } from '@base/models/user.model';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    SharedModule,
    TableModule
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent {

  _users: UserModel[] | null = null;

  @Input() set users(users: UserModel[] | null) {
    this._users = users;
  }

  @Input() isUsedForMakler = false;

  @Output() addUser = new EventEmitter<void>();
  @Output() editUser = new EventEmitter<UserModel>;

  getSingleUsageName(): string {
    if (this.isUsedForMakler) {
      return 'Vermittler';
    }
    return 'Tippgeber';
  }

  getPluralUsageName(): string {
    if (this.isUsedForMakler) {
      return 'Vermittler';
    }
    return 'Tippgeber';
  }
}
