import { Injectable } from '@angular/core';
import { BikeProductTypeEnum } from '@base/models/bike/bike-product-type.enum';

@Injectable()
export class BikePremiumCalculationService {

  getBikePremium(price: number): number | null {
    if (isNaN(price)) {
      return null;
    }

    if (price < 499.99) {
      return 69;
    } else if (price < 999.99) {
      return 119;
    } else if (price < 1499.99) {
      return 139;
    } else if (price < 1999.99) {
      return 169;
    } else if (price < 2499.99) {
      return 199;
    } else if (price < 2999.99) {
      return 219;
    } else if (price < 3999.99) {
      return 249;
    } else if (price < 4999.99) {
      return 299;
    } else if (price < 5999.99) {
      return 349;
    } else if (price < 6999.99) {
      return 399;
    } else if (price < 7999.99) {
      return 429;
    } else if (price < 8999.99) {
      return 459;
    } else if (price < 9999.99) {
      return 499;
    } else if (price < 12499.99) {
      return 599;
    } else if (price <= 15000) {
      return 699;
    }

    return null;
  }

  getEbikePremium(price: number): number | null {
    if (isNaN(price)) {
      return null;
    }

    if (price < 499.99) {
      return 49;
    } else if (price < 999.99) {
      return 69;
    } else if (price < 1499.99) {
      return 79;
    } else if (price < 1999.99) {
      return 89;
    } else if (price < 2499.99) {
      return 99;
    } else if (price < 2999.99) {
      return 109;
    } else if (price < 3999.99) {
      return 129;
    } else if (price < 4999.99) {
      return 189;
    } else if (price < 5999.99) {
      return 199;
    } else if (price < 6999.99) {
      return 229;
    } else if (price < 7999.99) {
      return 259;
    } else if (price < 8999.99) {
      return 299;
    } else if (price < 9999.99) {
      return 349;
    } else if (price < 12499.99) {
      return 499;
    } else if (price <= 15000) {
      return 599;
    }

    return null;
  }

  getBikePremiumV2(price: number, bikeProductType: BikeProductTypeEnum, divider = 1): number | null {
    if (bikeProductType === BikeProductTypeEnum.DIEBSTAHL) {
      return this.getBikePremiumV2ForDiebstahl(price, divider);
    } else if (bikeProductType === BikeProductTypeEnum.KOMPAKT_PAKET) {
      return this.getBikePremiumV2ForKompakt(price, divider);
    } else if (bikeProductType === BikeProductTypeEnum.PREMIUM_PAKET) {
      return this.getBikePremiumV2ForPremium(price, divider);
    }

    return null;
  }

  private getBikePremiumV2ForDiebstahl(price: number, divider = 1): number | null {
    let premium = 0;

    if (price >= 0 && price <= 499.99) {
      premium = 49.00;
    } else if (price >= 500 && price <= 999.99) {
      premium = 69.00;
    } else if (price >= 1000 && price <= 1499.99) {
      premium = 79.00;
    } else if (price >= 1500 && price <= 1999.99) {
      premium = 99.00;
    } else if (price >= 2000 && price <= 2499.99) {
      premium = 119.00;
    } else if (price >= 2500 && price <= 2999.99) {
      premium = 149.00;
    } else if (price >= 3000 && price <= 3999.99) {
      premium = 169.00;
    } else if (price >= 4000 && price <= 4999.99) {
      premium = 219.00;
    } else if (price >= 5000 && price <= 5999.99) {
      premium = 259.00;
    } else if (price >= 6000 && price <= 6999.99) {
      premium = 279.00;
    } else if (price >= 7000 && price <= 7999.99) {
      premium = 329.00;
    } else if (price >= 8000 && price <= 8999.99) {
      premium = 379.00;
    } else if (price >= 9000 && price <= 9999.99) {
      premium = 429.00;
    } else if (price >= 10000 && price <= 12499.99) {
      premium = 529.00;
    } else if (price >= 12500 && price <= 15000) {
      premium = 629.00;
    }

    return premium / divider;
  }

  private getBikePremiumV2ForKompakt(price: number, divider = 1): number | null {
    let premium = 0;

    if (price >= 0 && price <= 499.99) {
      premium = 59.00;
    } else if (price >= 500 && price <= 999.99) {
      premium = 99.00;
    } else if (price >= 1000 && price <= 1499.99) {
      premium = 109.00;
    } else if (price >= 1500 && price <= 1999.99) {
      premium = 129.00;
    } else if (price >= 2000 && price <= 2499.99) {
      premium = 149.00;
    } else if (price >= 2500 && price <= 2999.99) {
      premium = 169.00;
    } else if (price >= 3000 && price <= 3999.99) {
      premium = 189.00;
    } else if (price >= 4000 && price <= 4999.99) {
      premium = 239.00;
    } else if (price >= 5000 && price <= 5999.99) {
      premium = 279.00;
    } else if (price >= 6000 && price <= 6999.99) {
      premium = 299.00;
    } else if (price >= 7000 && price <= 7999.99) {
      premium = 349.00;
    } else if (price >= 8000 && price <= 8999.99) {
      premium = 399.00;
    } else if (price >= 9000 && price <= 9999.99) {
      premium = 449.00;
    } else if (price >= 10000 && price <= 12499.99) {
      premium = 549.00;
    } else if (price >= 12500 && price <= 15000) {
      premium = 649.00;
    }

    return premium / divider;
  }

  private getBikePremiumV2ForPremium(price: number, divider = 1): number | null {
    let premium = 0;

    if (price >= 0 && price <= 499.99) {
      premium = 79.00;
    } else if (price >= 500 && price <= 999.99) {
      premium = 119.00;
    } else if (price >= 1000 && price <= 1499.99) {
      premium = 129.00;
    } else if (price >= 1500 && price <= 1999.99) {
      premium = 159.00;
    } else if (price >= 2000 && price <= 2499.99) {
      premium = 179.00;
    } else if (price >= 2500 && price <= 2999.99) {
      premium = 199.00;
    } else if (price >= 3000 && price <= 3999.99) {
      premium = 239.00;
    } else if (price >= 4000 && price <= 4999.99) {
      premium = 299.00;
    } else if (price >= 5000 && price <= 5999.99) {
      premium = 349.00;
    } else if (price >= 6000 && price <= 6999.99) {
      premium = 399.00;
    } else if (price >= 7000 && price <= 7999.99) {
      premium = 449.00;
    } else if (price >= 8000 && price <= 8999.99) {
      premium = 499.00;
    } else if (price >= 9000 && price <= 9999.99) {
      premium = 549.00;
    } else if (price >= 10000 && price <= 12499.99) {
      premium = 649.00;
    } else if (price >= 12500 && price <= 15000) {
      premium = 749.00;
    }

    return premium / divider;
  }
}
