import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import { UserAddComponent } from '../../components/user-add/user-add.component';
import { UserBasePageComponent } from '../user-base-page/user-base-page.component';
import { UserListComponent } from '../../components/user-list/user-list.component';
import { AdminApiService } from '../../services/admin-api.service';
import { Observable } from 'rxjs';
import { UserModel } from '@base/models/user.model';

@Component({
  selector: 'app-users-page',
  standalone: true,
  imports: [
    UserListComponent,
    UserAddComponent,
    AsyncPipe,
    PageTitleComponent,
    UserAddComponent
  ],
  templateUrl: './makler-page.component.html',
  styleUrl: './makler-page.component.scss',
  providers: [AdminApiService]
})
export class MaklerPageComponent extends UserBasePageComponent {

  override users$: Observable<UserModel[]> = this.adminApiService.getAllMaklers();
}
