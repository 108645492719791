@if (user$ | async; as user) {
  <div
    class="row">
    <div class="col-md-8 offset-md-2">
      <ul class="list-group">
        @if (user.permissions?.includes(ServoPermissionEnum.Kaufpreis)) {
          <li
            role="listitem"
            class="list-group-item"
            [ngClass]="{'is-active': premiumCalcType === InsuranceTypeEnum.KAUFPREIS}"
            (keyup.enter)="premiumCalcType = InsuranceTypeEnum.KAUFPREIS"
            (click)="premiumCalcType = InsuranceTypeEnum.KAUFPREIS">
            <div class="preview">
              <span class="text">Kaufpreis Kasko</span>
              <span class="icon"><i class="bi bi-chevron-right"></i></span>
            </div>

            <div class="premium-calc-wrapper">
              @if (premiumCalcType === InsuranceTypeEnum.KAUFPREIS) {
                <app-kaufpreis-premium-calculation
                  (submitted)="routeToCalculator(InsuranceTypeEnum.KAUFPREIS, $event, user.servoAdvertiserId)"></app-kaufpreis-premium-calculation>
              }
            </div>
          </li>
        }

        @if (user.permissions?.includes(ServoPermissionEnum.Bike)) {
          <li
            role="listitem"
            class="list-group-item"
            [ngClass]="{'is-active': premiumCalcType === InsuranceTypeEnum.BIKE}"
            (keyup.enter)="premiumCalcType = InsuranceTypeEnum.BIKE"
            (click)="premiumCalcType = InsuranceTypeEnum.BIKE">

            <div class="preview">
              <span class="text">Bike/E-Bike</span>
              <span class="icon"><i class="bi bi-chevron-right"></i></span>
            </div>

            <div class="premium-calc-wrapper">
              @if (premiumCalcType === InsuranceTypeEnum.BIKE) {
                <app-bike-premium-calculation

                  (submitted)="routeToCalculator(InsuranceTypeEnum.BIKE, $event, user.servoAdvertiserId)"></app-bike-premium-calculation>
              }
            </div>
          </li>
        }

        @if (user.permissions?.includes(ServoPermissionEnum.Tech)) {
          <li
            role="listitem"
            class="list-group-item"
            [ngClass]="{'is-active': premiumCalcType === InsuranceTypeEnum.ELEKTRONIK}"
            (keyup.enter)="premiumCalcType = InsuranceTypeEnum.ELEKTRONIK"
            (click)="premiumCalcType = InsuranceTypeEnum.ELEKTRONIK">

            <div class="preview">
              <span class="text">Elektronik Versicherung</span>
              <span class="icon"><i class="bi bi-chevron-right"></i></span>
            </div>

            <div class="premium-calc-wrapper">
              @if (premiumCalcType === InsuranceTypeEnum.ELEKTRONIK) {
                <app-elektronik-premium-calculation
                  (submitted)="routeToCalculator(InsuranceTypeEnum.ELEKTRONIK, $event, user.servoAdvertiserId)"></app-elektronik-premium-calculation>
              }
            </div>
          </li>
        }

        @if (user.permissions?.includes(ServoPermissionEnum.GarantieVerlaengerung)) {
          <li
            role="listitem"
            class="list-group-item"
            [ngClass]="{'is-active': premiumCalcType === InsuranceTypeEnum.GARANTIE}"
            (keyup.enter)="premiumCalcType = InsuranceTypeEnum.GARANTIE"
            (click)="premiumCalcType = InsuranceTypeEnum.GARANTIE">

            <div class="preview">
              <span class="text">Garantieverlängerung</span>
              <span class="icon"><i class="bi bi-chevron-right"></i></span>
            </div>

            <div class="premium-calc-wrapper">
              @if (premiumCalcType === InsuranceTypeEnum.GARANTIE) {
                <app-garantie-premium-calculation
                  (submitted)="routeToCalculator(InsuranceTypeEnum.GARANTIE, $event, user.servoAdvertiserId)"></app-garantie-premium-calculation>
              }
            </div>
          </li>
        }
      </ul>
    </div>
  </div>
}


