import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GarantiePremiumCalculation, PremiumCalculation } from '../calculator-list/calculator-list.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-garantie-premium-calculation',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './garantie-premium-calculation.component.html',
  styleUrl: './garantie-premium-calculation.component.scss'
})
export class GarantiePremiumCalculationComponent implements OnInit, OnDestroy {

  fb = inject(FormBuilder);
  form = this.fb.group({
    isPremiumSchutz: new FormControl<boolean | null>(null, [Validators.required]),
    premium: new FormControl<number | null>({ value: null, disabled: true }, [])
  });
  showError = false;
  componentDestroyed$ = new Subject();

  @Output() submitted = new EventEmitter<PremiumCalculation>();

  ngOnInit() {
    this.form
      .controls
      .isPremiumSchutz
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(isPremium => {
        const premiumCtrl = this.form.controls.premium;
        if (isPremium) {
          premiumCtrl.setValue(379, { emitEvent: false });
        } else {
          premiumCtrl.setValue(199, { emitEvent: false });
        }
      });
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.submitted.emit(this.form.getRawValue() as GarantiePremiumCalculation);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(null);
    this.componentDestroyed$.complete();
  }
}
