<header class="p-3 mb-3 border-bottom bg-white">
  <div class="container">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
      <div class="d-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none">
        <img
          alt="myservo logo"
          class="bi me-md-4"
          role="img"
          src="/assets/logo.png"
          width="80">
      </div>

      <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
        <li
          (click)="routeTo('overview')"
          (keyup.enter)="routeTo('overview')"
          role="presentation">

          <a
            [ngClass]="{'is-active': isCurrentRouteActive('overview')}"
            class="nav-link px-2">Rechner</a>
        </li>

        @if (user?.permissions?.includes(ServoPermissionsEnum.SuperAdmin)) {
          <li
            role="listitem"
            (click)="routeTo('admin/users')"
            (keyup.enter)="routeTo('admin/users')">

            <a
              class="nav-link px-2"
              [ngClass]="{'is-active': isCurrentRouteActive('admin/users')}">Vermittler</a>
          </li>
        }

        @if (user?.permissions?.includes(ServoPermissionsEnum.SuperAdmin)) {
          <li
            role="listitem"
            (click)="routeTo('admin/referrers')"
            (keyup.enter)="routeTo('admin/referrers')">

            <a
              class="nav-link px-2"
              [ngClass]="{'is-active': isCurrentRouteActive('admin/referrers')}">Tippgeber</a>
          </li>
        }
      </ul>

      @if (user?.logoUrl) {
        <div class="d-none d-lg-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none">

          <img
            [src]="user?.logoUrl"
            class="bi ms-md-4"
            width="80"
            role="img"
            alt="myservo logo">
        </div>
      }

      <div
        (click)="logout()"
        (keyup.enter)="logout()"
        class="ms-3 logout-button"
        role="presentation">
        <i class="bi bi-box-arrow-right me-2"></i>
        <span class="fw-medium">Ausloggen</span>
      </div>
    </div>
  </div>
</header>
