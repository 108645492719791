import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@base/services/user.service';
import { JwtService } from '@base/services/jwt.service';

export const authGuard: CanActivateFn = (route) => {
  const jwtService = inject(JwtService);
  const userService = inject(UserService);

  if (!jwtService.isTokenValid()) {
    navigateToDefault();
    return false;
  }

  if (!route.data) {
    return true;
  }

  const permissions = route.data['permissions'];
  const needsOnlyOnePermission = route.data['needsOnlyOnePermission'];

  if (needsOnlyOnePermission) {
    return userService.hasSomePermission(permissions);
  }

  if (permissions && !userService.hasEveryPermission(permissions)) {
    console.error('No permission for path: ' + route.pathFromRoot);
    navigateToDefault();
    return false;
  }

  return true;
};

function navigateToDefault() {
  const router = inject(Router);
  router.navigate(['/']);
}
