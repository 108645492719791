import { Component } from '@angular/core';
import { PasswordResetBoxComponent } from '../../components/password-reset-box/password-reset-box.component';
import { RequestResetBoxComponent } from '../../components/request-reset-box/request-reset-box.component';
import { PasswordApiService } from '../../services/password-api.service';

@Component({
  selector: 'app-password-request-page',
  standalone: true,
  imports: [
    PasswordResetBoxComponent,
    RequestResetBoxComponent
  ],
  providers: [
    PasswordApiService
  ],
  templateUrl: './password-request-page.component.html',
  styleUrl: './password-request-page.component.scss'
})
export class PasswordRequestPageComponent {

}
