import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { PremiumCalculation } from '../calculator-list/calculator-list.component';
import { BikePremiumCalculationService } from '../../services/bike-premium-calculation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bike-premium-calculation',
  standalone: true,
  imports: [
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule
  ],
  providers: [
    BikePremiumCalculationService
  ],
  templateUrl: './bike-premium-calculation.component.html',
  styleUrl: './bike-premium-calculation.component.scss'
})
export class BikePremiumCalculationComponent implements OnInit, OnDestroy {

  fb = inject(FormBuilder);
  calcService = inject(BikePremiumCalculationService);

  form = this.fb.group({
    isEbike: new FormControl<boolean>(false),
    price: new FormControl<number | null>(null, [Validators.required, Validators.min(0), Validators.max(15000)]),
    premium: new FormControl<number | null>({ value: null, disabled: true }, [])
  });

  @Output() submitted = new EventEmitter<PremiumCalculation>();

  showError = false;
  componentDestroyed$ = new Subject();

  ngOnInit() {
    this.form
      .controls
      .price
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(price => {
        const premiumCtrl = this.form.controls.premium;
        const priceCtrl = this.form.controls.price;
        const isEbike = this.form.controls.isEbike.value;

        if (this.isPriceValid(priceCtrl.value)) {
          const premium = isEbike ? this.calcService.getEbikePremium(price!) : this.calcService.getBikePremium(price!);
          premiumCtrl.setValue(premium, { emitEvent: false });
        } else {
          premiumCtrl.setValue(null, { emitEvent: false });
        }
      });

    this.form
      .controls
      .isEbike
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(isEbike => {
        const premiumCtrl = this.form.controls.premium;
        const priceCtrl = this.form.controls.price;

        if (this.isPriceValid(priceCtrl.value)) {
          const premium = isEbike ? this.calcService.getEbikePremium(priceCtrl.value!) : this.calcService.getBikePremium(priceCtrl.value!);
          premiumCtrl.setValue(premium, { emitEvent: false });
        } else {
          premiumCtrl.setValue(null, { emitEvent: false });
        }
      });
  }

  isPriceValid(price: number | null): boolean {
    if (!price) {
      return false;
    }

    return price > 499.99 && price <= 15000;
  }

  submit(): void {
    this.form.markAllAsTouched();

    const priceCtrl = this.form.controls.price;
    if (this.isPriceValid(priceCtrl.value)) {
      this.showError = false;
    } else {
      this.showError = true;
      return;
    }

    if (this.form.invalid) {
      return;
    }

    this.submitted.emit(this.form.getRawValue() as PremiumCalculation);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(null);
    this.componentDestroyed$.complete();
  }
}
