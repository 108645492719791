<div class="request-reset-box">
  <form
    (ngSubmit)="submit()"
    [formGroup]="form">
    <img
      alt="servo-logo"
      class="mb-4"
      height="57"
      src="https://myservo.at/wp-content/uploads/2021/05/servo-logo-400.png">

    @if (!showSuccessMessage) {
      <ng-template [ngTemplateOutlet]="formContent"></ng-template>
    } @else {
      <div class="mb-3">
        <span class="text-success">
          Sie erhalten in Kürze eine E-Mail, um Ihr Passwort zurückzusetzen.
          Sie können diese Seite nun schließen.
        </span>
      </div>
    }
  </form>
</div>

<ng-template #formContent>
  <div class="mb-3 text-center">
      <span class="fw-medium">
        Bitte geben Sie Ihre E-Mail Adresse ein, um Ihr Passwort zurückzusetzen.
      </span>
  </div>

  <div class="form-floating mb-3">
    <input
      [formControl]="form.controls.email"
      class="form-control"
      id="floatingInput"
      placeholder="name@example.com"
      type="email">
    <label for="floatingInput">E-Mail Adresse</label>
  </div>

  @if (form.invalid && submitted) {
    <div class="mt-3">
      <span class="text-danger fw-medium">
        E-Mail oder Passwort ist falsch!
      </span>
    </div>
  }

  <button
    class="btn btn-primary w-100 py-2 mt-3"
    type="submit">
    Passwort zurücksetzen
  </button>
</ng-template>
