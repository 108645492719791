import { Injectable } from '@angular/core';

@Injectable()
export class ElektronikPremiumCalculationService {

  getPremium(price: number): number | null {
    if (isNaN(price)) {
      return null;
    }

    if (price < 299.99) {
      return 34.95;
    } else if (price < 599.99) {
      return 64.95;
    } else if (price < 899.99) {
      return 84.95;
    } else if (price < 1499.99) {
      return 114.95;
    } else if (price < 2999.99) {
      return 149.99;
    } else if (price < 5000) {
      return 199.99;
    }

    return null;
  }
}
