import { Component, inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AsyncPipe, NgClass } from '@angular/common';
import { InsuranceTypeEnum } from '@base/models/insurance-type.enum';
import { UserService } from '@base/services/user.service';
import { ServoPermissionsEnum } from '@base/models/servo-permissions.enum';
import { JwtService } from '@base/services/jwt.service';
import { ElektronikPremiumCalculationComponent } from '../elektronik-premium-calculation/elektronik-premium-calculation.component';
import { KaufpreisPremiumCalculationComponent } from '../kaufpreis-premium-calculation/kaufpreis-premium-calculation.component';
import { BikePremiumCalculationComponent } from '../bike-premium-calculation/bike-premium-calculation.component';
import { GarantiePremiumCalculationComponent } from '../garantie-premium-calculation/garantie-premium-calculation.component';

@Component({
  selector: 'app-calculator-list',
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    ElektronikPremiumCalculationComponent,
    KaufpreisPremiumCalculationComponent,
    BikePremiumCalculationComponent,
    GarantiePremiumCalculationComponent
  ],
  templateUrl: './calculator-list.component.html',
  styleUrl: './calculator-list.component.scss'
})
export class CalculatorListComponent {

  jwtService = inject(JwtService);
  InsuranceTypeEnum = InsuranceTypeEnum;
  ServoPermissionEnum = ServoPermissionsEnum;

  user$ = inject(UserService).currentUser$;

  premiumCalcType: InsuranceTypeEnum | null = null;

  routeToCalculator(type: InsuranceTypeEnum, result: PremiumCalculation | GarantiePremiumCalculation, servoAdvertiserId: string): void {
    if (type === InsuranceTypeEnum.KAUFPREIS) {
      window.location.href = `${environment.kaufpreisUi}?premium=${result.premium}&price=${result.price}&advertiserId=${servoAdvertiserId}`;
    } else if (type === InsuranceTypeEnum.BIKE) {
      window.location.href = `${environment.bikeUi}?premium=${result.premium}&price=${result.price}&advertiserId=${servoAdvertiserId}&isEbike=${result.isEbike}`;
    } else if (type === InsuranceTypeEnum.ELEKTRONIK) {
      window.location.href = `${environment.techUi}?premium=${result.premium}&price=${result.price}&advertiserId=${servoAdvertiserId}`;
    } else if (type === InsuranceTypeEnum.GARANTIE) {
      window.location.href = `${environment.garantieUi}?premium=${result.premium}&isPremiumSchutz=${(result as GarantiePremiumCalculation).isPremiumSchutz}&advertiserId=${servoAdvertiserId}`;
    }
  }
}

export interface PremiumCalculation {
  premium: number;
  price: number;
  isEbike?: boolean;
}

export interface GarantiePremiumCalculation extends PremiumCalculation {
  isPremiumSchutz: boolean;
}
