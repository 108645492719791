import { Component, inject, Input } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { UserModel } from '@base/models/user.model';
import { ServoPermissionsEnum } from '@base/models/servo-permissions.enum';
import { UserService } from '@base/services/user.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgClass
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  @Input() user: UserModel | null = null;

  router = inject(Router);
  userService = inject(UserService);

  routeTo(route: routeType): void {
    this.router.navigate([`/${route}`]);
  }

  isCurrentRouteActive(route: routeType): boolean {
    return window.location.href.endsWith(route);
  }

  logout(): void {
    this.userService.logout();
  }

  protected readonly ServoPermissionsEnum = ServoPermissionsEnum;
}

export type routeType = 'overview' | 'admin/users' | 'admin/referrers'
