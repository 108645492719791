import { Component } from '@angular/core';
import { PasswordResetBoxComponent } from '../../components/password-reset-box/password-reset-box.component';

@Component({
  selector: 'app-password-reset-page',
  standalone: true,
  imports: [
    PasswordResetBoxComponent
  ],
  templateUrl: './password-reset-page.component.html',
  styleUrl: './password-reset-page.component.scss'
})
export class PasswordResetPageComponent {
  constructor() {

  }
}
