import { Routes } from '@angular/router';
import { MaklerPageComponent } from './features/admin/pages/makler-page/makler-page.component';
import { ServoPermissionsEnum } from '@base/models/servo-permissions.enum';
import { authGuard } from './shared/guards/auth.guard';
import { OverviewPageComponent } from './features/calculator-overview/pages/overview-page/overview-page.component';
import { LoginPageComponent } from './features/login/pages/login-page/login-page.component';
import { PasswordResetPageComponent } from './features/password/pages/password-reset-page/password-reset-page.component';
import { PasswordRequestPageComponent } from './features/password/pages/password-request-page/password-request-page.component';
import { NonMaklerPageComponent } from './features/admin/pages/non-makler-page/non-makler-page.component';

export const routes: Routes = [
  {
    path: 'admin/users',
    loadComponent: () => MaklerPageComponent,
    canActivate: [authGuard],
    data: {
      permissions: [ServoPermissionsEnum.SuperAdmin]
    }
  },
  {
    path: 'admin/referrers',
    loadComponent: () => NonMaklerPageComponent,
    canActivate: [authGuard],
    data: {
      permissions: [ServoPermissionsEnum.SuperAdmin]
    }
  },
  {
    path: 'overview',
    loadComponent: () => OverviewPageComponent,
    canActivate: [authGuard],
    data: {
      permissions: [
        ServoPermissionsEnum.Bike,
        ServoPermissionsEnum.Kaufpreis,
        ServoPermissionsEnum.Tech,
        ServoPermissionsEnum.GarantieVerlaengerung
      ],
      needsOnlyOnePermission: true
    }
  },
  {
    path: '',
    component: LoginPageComponent
  },
  {
    path: 'password',
    children: [
      { path: 'requestReset', loadComponent: () => PasswordRequestPageComponent },
      { path: 'reset', loadComponent: () => PasswordResetPageComponent }
    ]
  }
];
