import { Component, inject } from '@angular/core';
import { LoginApiService } from '../../services/login-api.service';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginResponse } from '@base/models/login-response.model';
import { JwtService } from '@base/services/jwt.service';
import { UserService } from '@base/services/user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login-box',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './login-box.component.html',
  styleUrl: './login-box.component.scss',
  providers: [LoginApiService]
})
export class LoginBoxComponent {

  loginService = inject(LoginApiService);
  jwtService = inject(JwtService);
  userService = inject(UserService);
  fb = inject(FormBuilder);
  router = inject(Router);

  form = this.fb.group({
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    password: new FormControl<string | null>(null, [Validators.required]),
    rememberMe: new FormControl<boolean>(true)
  });

  passwordInvalid = false;

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const email = this.form.controls.email.value as NonNullable<string>;

    this.loginService.login({
      email: email,
      password: this.form.controls.password.value as NonNullable<string>,
      rememberMe: true
    }).subscribe({
      next: (loginResponse: LoginResponse) => this.handleLoginSuccess(loginResponse),
      error: (error: HttpErrorResponse) => this.handleLoginError(error, email)
    });
  }

  handleLoginSuccess(loginResponse: LoginResponse): void {
    this.passwordInvalid = false;
    this.jwtService.setToken(loginResponse.accessToken);
    this.userService.setUser(loginResponse.user);
    this.router.navigate(['/overview']);
  }

  handleLoginError(error: HttpErrorResponse, email: string): void {
    if (error.status === 401) {
      this.passwordInvalid = true;
      return;
    }

    if (error.status === 409) {
      this.router.navigate(['/password/reset'], { state: { email: email } });
    }
  }
}
