import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserFormModel } from '../../models/form/user-form.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AdminApiService } from '../../services/admin-api.service';
import { InsuranceTypeEnum } from '@base/models/insurance-type.enum';
import { QRCodeModule } from 'angularx-qrcode';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserModel } from '@base/models/user.model';

@Component({
  selector: 'app-user-add',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    QRCodeModule
  ],
  templateUrl: './user-add.component.html',
  styleUrl: './user-add.component.scss'
})
export class UserAddComponent implements OnInit {

  @Input() user: UserModel | null = null;
  @Input() isUsedForMakler = true;

  @Output() discardChanges = new EventEmitter<void>();
  @Output() submitUser = new EventEmitter<UserModel>();

  fb = inject(FormBuilder);
  form = this.fb.group<UserFormModel>({
    id: new FormControl<string | null>(null),
    isPerson: new FormControl<boolean | null>(false),
    companyName: new FormControl<string | null>(null),
    firstname: new FormControl<string | null>(null),
    lastname: new FormControl<string | null>(null),
    logoUrl: new FormControl<string | null>(null),
    email: new FormControl<string | null>(null),
    hwAdvertiserId: new FormControl<number | null>(null, [Validators.required, Validators.max(99999999999)]), // maximum 11 digits
    servoAdvertiserId: new FormControl<string | null>(null, [Validators.required]),
    additionalMailForCc: new FormControl<string | null>(null, [Validators.email])
  });

  adminApiService = inject(AdminApiService);
  sanitizer = inject(DomSanitizer);

  advertiserLink: string = '';
  showCopyMessage = false;

  constructor() {
    this.form
      .controls
      .isPerson
      .valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(isPerson => this.setValidators(isPerson!));
  }

  ngOnInit() {
    if (this.user) {
      this.form.patchValue(this.user);
      this.form.disable();
      this.form.controls.additionalMailForCc.enable();
    }

    if (this.isUsedForMakler) {
      this.form.controls.email.setValidators([Validators.required, Validators.email]);
    }
  }

  setValidators(isPerson: boolean): void {
    if (isPerson) {
      this.form.controls.firstname.setValidators([Validators.required]);
      this.form.controls.lastname.setValidators([Validators.required]);
      this.form.controls.companyName.setValidators(null);
    } else {
      this.form.controls.firstname.setValidators(null);
      this.form.controls.lastname.setValidators(null);
      this.form.controls.companyName.setValidators([Validators.required]);
    }

    this.form.controls.firstname.updateValueAndValidity();
    this.form.controls.lastname.updateValueAndValidity();
    this.form.controls.companyName.updateValueAndValidity();
  }

  createAdvertiserLink(): void {
    if (!this.form.controls.servoAdvertiserId.value) {
      return;
    }

    this.adminApiService
      .createAdvertiserLink(this.form.controls.servoAdvertiserId.value, InsuranceTypeEnum.BIKE)
      .subscribe(link => {
        this.advertiserLink = link;
        navigator.clipboard.writeText(this.advertiserLink).then(() => this.showCopyMessage = true);
        window.setTimeout(() => this.showCopyMessage = false, 3000);
      });
  }

  getSingleUsageName(): string {
    if (this.isUsedForMakler) {
      return 'Vermittler';
    }
    return 'Tippgeber';
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.submitUser.emit({
      ...this.form.getRawValue() as NonNullable<UserModel>,
      isMakler: this.isUsedForMakler
    });
  }

  downloadQrCode(safeUrl: SafeUrl): void {
    const url = (this.sanitizer.sanitize(4, safeUrl) || '') as string;

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `QR-Code ${this.form.controls.servoAdvertiserId.value}`;
    document.body.appendChild(anchor); // Required for Firefox

    // Simulate click on the anchor to start download
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
  }
}