import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/api.service';
import { Observable } from 'rxjs';
import { UserModel } from '@base/models/user.model';

@Injectable({ providedIn: 'root' })
export class UserApiService extends ApiService {

  getProfile(): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.endpoint}/auth/profile`);
  }

}
