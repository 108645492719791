import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PremiumCalculation } from '../calculator-list/calculator-list.component';
import { ElektronikPremiumCalculationService } from '../../services/elektronik-premium-calculation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-elektronik-premium-calculation',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    ElektronikPremiumCalculationService
  ],
  templateUrl: './elektronik-premium-calculation.component.html',
  styleUrl: './elektronik-premium-calculation.component.scss'
})
export class ElektronikPremiumCalculationComponent implements OnInit, OnDestroy {

  fb = inject(FormBuilder);
  calcService = inject(ElektronikPremiumCalculationService);

  form = this.fb.group({
    price: new FormControl<number | null>(null, [Validators.required]),
    premium: new FormControl<number | null>({ value: null, disabled: true }, [])
  });

  @Output() submitted = new EventEmitter<PremiumCalculation>();

  showError = false;
  componentDestroyed$ = new Subject();

  ngOnInit() {
    this.form
      .controls
      .price
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(price => {
        const premiumCtrl = this.form.controls.premium;
        const priceCtrl = this.form.controls.price;

        if (this.isPriceValid(priceCtrl.value)) {
          premiumCtrl.setValue(this.calcService.getPremium(price!), { emitEvent: false });
        } else {
          premiumCtrl.setValue(null, { emitEvent: false });
        }
      });
  }

  isPriceValid(price: number | null): boolean {
    if (!price) {
      return false;
    }

    return price > 0 && price < 5000;
  }

  submit(): void {
    this.form.markAllAsTouched();

    const priceCtrl = this.form.controls.price;
    if (this.isPriceValid(priceCtrl.value)) {
      this.showError = false;
    } else {
      this.showError = true;
      return;
    }

    if (this.form.invalid) {
      return;
    }

    this.submitted.emit(this.form.getRawValue() as PremiumCalculation);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(null);
    this.componentDestroyed$.complete();
  }
}
