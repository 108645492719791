import { ApiService } from '../../../core/services/api.service';
import { Observable } from 'rxjs';
import { LoginModel } from '../models/login.model';
import { Injectable } from '@angular/core';
import { LoginResponse } from '@base/models/login-response.model';

@Injectable()
export class LoginApiService extends ApiService {

  login(login: LoginModel): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.endpoint}/auth/login`, login);
  }
}
