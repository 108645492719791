import { Directive, inject } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';
import { UserModel } from '@base/models/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateUserDto } from '@base/models/dto/create-user.dto';
import { UpdateUserDto } from '@base/models/dto/update-user.dto';

@Directive({
  standalone: true,
  providers: [AdminApiService]
})
export abstract class UserBasePageComponent {

  showAddUser = false;
  userSaveSuccess = false;
  userSaveError: string | null = null;

  adminApiService = inject(AdminApiService);
  users$: Observable<UserModel[]> | null = null;

  userToEdit: UserModel | null = null;

  submitUser(user: UserModel): void {
    if (user.id) {
      this.update(user);
      return;
    }

    this.save(user);
  }

  save(user: UserModel): void {
    const dto: CreateUserDto = {
      email: user.email,
      isPerson: user.isPerson,
      isMakler: user.isMakler,
      servoAdvertiserId: user.servoAdvertiserId,
      hwAdvertiserId: user.hwAdvertiserId,
      logoUrl: user.logoUrl,
      additionalMailForCc: user.additionalMailForCc,
      companyName: user.companyName,
      firstname: user.firstname,
      lastname: user.lastname
    };

    this.adminApiService
      .saveUser(dto)
      .subscribe({
        next: () => this.handleSubmitSuccess(),
        error: (error: HttpErrorResponse) => this.userSaveError = error.error.message
      });
  }

  update(user: UserModel): void {
    const dto: UpdateUserDto = {
      additionalMailForCc: user.additionalMailForCc
    };

    this.adminApiService
      .updateUser(user.id!, dto)
      .subscribe({
        next: () => this.handleSubmitSuccess(),
        error: (error: HttpErrorResponse) => this.userSaveError = error.error.message
      });
  }

  handleSubmitSuccess(): void {
    this.showAddUser = false;
    this.userSaveSuccess = true;
    this.userSaveError = null;
    window.setTimeout(() => this.userSaveSuccess = false, 3000);
  }

  discardChanges(): void {
    this.userSaveError = null;
    this.showAddUser = false;
    this.userToEdit = null;
  }

  onEditUser(user: UserModel): void {
    this.userToEdit = user;
    this.showAddUser = true;
  }
}
