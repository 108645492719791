<div class="login-box">
  <form
    (ngSubmit)="submit()"
    [formGroup]="form">
    <img
      alt="servo-logo"
      class="mb-4"
      height="57"
      src="/assets/logo.png">

    <div class="form-floating mb-3">
      <input
        [formControl]="form.controls.email"
        class="form-control"
        id="floatingInput"
        placeholder="name@example.com"
        type="email">
      <label for="floatingInput">E-Mail Adresse</label>
    </div>

    <div class="form-floating">
      <input
        [formControl]="form.controls.password"
        class="form-control"
        id="floatingPassword"
        placeholder="Password"
        type="password">
      <label for="floatingPassword">Passwort</label>
    </div>

    @if (passwordInvalid) {
      <div class="mt-3">
        <span class="text-danger fw-medium">
          E-Mail oder Passwort ist falsch!
        </span>
      </div>
    }

    <button
      class="btn btn-primary w-100 py-2 mt-3"
      type="submit">
      Einloggen
    </button>

    <a
      class="text-muted small text-primary fw-medium mt-3"
      href="/password/requestReset">
      Passwort zurücksetzen
    </a>
  </form>
</div>
