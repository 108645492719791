import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/api.service';
import { Observable } from 'rxjs';
import { LoginResponse } from '@base/models/login-response.model';
import { ResetPasswordModel } from '../models/reset-password.model';

@Injectable()
export class PasswordApiService extends ApiService {

  requestPasswordReset(email: string): Observable<unknown> {
    return this.http.get<unknown>(`${this.endpoint}/user/password/request-reset?email=${email}`);
  }

  resetPassword(resetPassword: ResetPasswordModel): Observable<LoginResponse> {
    return this.http.put<LoginResponse>(`${this.endpoint}/user/password/reset`, resetPassword);
  }
}
