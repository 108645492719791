import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './core/components/header/header.component';
import { JwtService } from '@base/services/jwt.service';
import { UserService } from '@base/services/user.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  jwtService = inject(JwtService);
  userService = inject(UserService);
  router = inject(Router);

  ngOnInit(): void {
    if (this.jwtService.isTokenValid()) {
      this.userService.init();
    } else {
      this.jwtService.removeToken();
    }
  }

  showHeader(): boolean {
    return this.jwtService.hasToken() && window.location.href.includes('/admin') || window.location.href.includes('/overview');
  }
}
