import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { UserService } from '@base/services/user.service';
import { JwtService } from '@base/services/jwt.service';
import { PasswordApiService } from '../../services/password-api.service';
import { LoginResponse } from '@base/models/login-response.model';

@Component({
  selector: 'app-password-reset-box',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    PasswordApiService
  ],
  templateUrl: './password-reset-box.component.html',
  styleUrl: './password-reset-box.component.scss'
})
export class PasswordResetBoxComponent {

  fb = inject(FormBuilder);
  router = inject(Router);
  userService = inject(UserService);
  jwtService = inject(JwtService);
  passwordApiService = inject(PasswordApiService);

  email: string | undefined = undefined;

  form = this.fb.group({
    password: new FormControl<string | null>(null, [Validators.required])
  });

  constructor() {
    // @ts-expect-error has to be accessed this way
    this.email = this.router.getCurrentNavigation()?.extras?.state['email'];

    if (!this.email) {
      this.router.navigate(['/']);
      return;
    }
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.passwordApiService
      .resetPassword({
        email: this.email as NonNullable<string>,
        password: this.form.controls.password.value as NonNullable<string>
      })
      .subscribe({
        next: (loginResponse: LoginResponse) => this.handleLoginSuccess(loginResponse)
      });
  }

  handleLoginSuccess(loginResponse: LoginResponse): void {
    this.jwtService.setToken(loginResponse.accessToken);
    this.userService.setUser(loginResponse.user);
    this.router.navigate(['/overview']);
  }
}