import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserModel } from '@base/models/user.model';
import { ServoPermissionsEnum } from '@base/models/servo-permissions.enum';
import { UserApiService } from '@base/services/user-api.service';
import { JwtService } from '@base/services/jwt.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UserService {

  currentUser$ = new BehaviorSubject<UserModel | null>(null);
  private _user: UserModel | null = null;

  userApiService = inject(UserApiService);
  jwtService = inject(JwtService);
  router = inject(Router);

  init(): void {
    this.userApiService.getProfile().subscribe({
      next: (authenticatedUser: UserModel) => {
        this.setUser(authenticatedUser);
        this.router.navigate(['/overview']);
      },
      error: () => {
        this.jwtService.removeToken();
        this.router.navigate(['/']);
      }
    });
  }

  setUser(user: UserModel): void {
    this._user = user;
    this.currentUser$.next(user);
  }

  logout(): void {
    this.currentUser$.next(null);
    this._user = null;
    this.jwtService.removeToken();
    this.router.navigate(['/']);
  }

  hasPermission(permission: ServoPermissionsEnum): boolean {
    if (!this._user?.permissions || this._user?.permissions.length === 0) {
      return false;
    }

    return this._user?.permissions?.includes(permission);
  }

  hasEveryPermission(values: ServoPermissionsEnum[]): boolean {
    return values.every(p => this.hasPermission(p));
  }

  hasSomePermission(values: ServoPermissionsEnum[]): boolean {
    if (!this._user?.permissions || this._user?.permissions.length === 0) {
      return false;
    }

    return this._user.permissions.some(p => values.includes(p));
  }
}
