import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import { CalculatorListComponent } from '../../components/calculator-list/calculator-list.component';
import { UserAddComponent } from '../../../admin/components/user-add/user-add.component';
import { UserListComponent } from '../../../admin/components/user-list/user-list.component';

@Component({
  selector: 'app-overview-page',
  standalone: true,
  imports: [
    AsyncPipe,
    PageTitleComponent,
    UserAddComponent,
    UserListComponent,
    CalculatorListComponent
  ],
  templateUrl: './overview-page.component.html',
  styleUrl: './overview-page.component.scss'
})
export class OverviewPageComponent {

}
