import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/api.service';
import { Observable } from 'rxjs';
import { UserModel } from '@base/models/user.model';
import { InsuranceTypeEnum } from '@base/models/insurance-type.enum';
import { CreateUserDto } from '@base/models/dto/create-user.dto';
import { UpdateUserDto } from '@base/models/dto/update-user.dto';

@Injectable()
export class AdminApiService extends ApiService {

  getAllMaklers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${this.endpoint}/admin/user/maklers`);
  }

  getAllNonMaklers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${this.endpoint}/admin/user/non-maklers`);
  }

  saveUser(user: CreateUserDto): Observable<UserModel> {
    return this.http.post<UserModel>(`${this.endpoint}/admin/user`, user);
  }

  updateUser(id: string, user: UpdateUserDto): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.endpoint}/admin/user/${id}`, user);
  }

  createAdvertiserLink(servoAdvertiserId: string, insuranceType: InsuranceTypeEnum): Observable<string> {
    return this.http.get(`${this.endpoint}/admin/user/createAdvertiserLink?servoAdvertiserId=${servoAdvertiserId}&insuranceType=${insuranceType}`, { responseType: 'text' });
  }
}
