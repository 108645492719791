<main>
  @if (showHeader()) {
    <app-header
      [user]="userService.currentUser$ | async"></app-header>
  }

  <section class="app-wrapper container">
    <router-outlet></router-outlet>
  </section>
</main>
